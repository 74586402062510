import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.apply.report');
import { OnInit, Input, DoCheck } from '@angular/core';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-apply-report',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.apply.report/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.workspace-list-item {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: white;
}

.card {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 95%;
  max-width: 1440px;
  border: none;
  box-shadow: none;
  margin: 0 auto;
}

.btn-list {
  margin-top: 30px;
  width: 170px !important;
  height: 50px !important;
  text-align: center;
  margin-left: auto;
  background: #D1D1D1;
  font: normal normal normal 21px/23px NanumSquareOTF !important;
  border: none;
  border-radius: 10px;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
li a {
  text-decoration: underline;
}
li img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

hr {
  margin: 0;
  border: 2px solid #D1D1D1;
}

.content {
  width: 100%;
  min-height: 93%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content h2 {
  margin: 0 auto;
  width: 100%;
  text-align: center;
  font: normal normal bold 36px/41px NanumSquareOTF;
}
.content h3 {
  margin: 0 auto;
  width: 100%;
  text-align: left;
  font: normal normal bold 28px/35px NanumSquareOTF;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  background-color: white;
  text-align: left;
}

.wiz-form {
  margin: 0 auto;
  margin-top: 10px;
  width: 100%;
  background-color: white;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}
.wiz-form .wiz-form-label {
  font: normal normal normal 18px/21px NanumSquareOTF;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 1920px;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}
.season-page .header {
  border-radius: none;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-news {
  padding: 12px 30px;
  background: #5263FF 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: white;
  margin-left: 10px;
}

.btn-gray {
  padding: 12px 30px;
  margin-left: 10px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  background-color: #D1D1D1;
  border-radius: 10px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: url("/assets/portal/apply/featured/banner.png");
  min-height: 378px;
  max-height: 378px;
  width: 1920px;
  padding-bottom: 12px;
  align-items: flex-start;
}
.header .text-container {
  max-width: 1120px;
  margin-left: 240px;
  top: 216px;
}
.header .xmark {
  position: absolute;
  top: 48px;
  right: 48px;
  cursor: pointer;
}
.header .xmark i {
  color: #fff;
  font-size: 32px;
}
.header .xmark:hover i {
  color: #FF5933;
}
@media (max-width: 768px) {
  .header .xmark {
    top: 32px;
    right: 32px;
  }
}
.header .title {
  font-size: 36px;
}

.content {
  padding-top: 64px;
  padding-bottom: 176px;
  min-height: 100%;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
}
.content-container .content-body {
  max-width: 860px;
  margin: 0 auto;
}`],
})
export class PageApplyReportComponent implements OnInit {
    public submit_id: any;
    public notice = {
        title: '',
        planstart: '',
        planend: ''
    };
    public item = {
        id: '',
        user_id: '',
        application_id: '',
        comment_id: '',
        title: '',
        content: '',
        name: '',
        email: '',
        tel: '',
        institute: '',
        files: [],
        status: ''
    };

    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,    ) {
        this.submit_id = WizRoute.segment.id;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }

    public async download(file) {

        let download = wiz.url('download?id=' + this.item.id + "&title=" + file)

        window.open(download, '_blank');
    }


    public async load() {
        this.submit_id = WizRoute.segment.id;
        const { code, data } = await wiz.call("get", { 'id': this.submit_id });
        let { item, submit_notice } = data;
        this.item = item;
        this.item.files = JSON.parse(data.item.files.replace(/'/g, '"'));
        this.notice = submit_notice;
        await this.service.render();

    }
}

export default PageApplyReportComponent;