import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.institutes.event.detail');
import { OnInit } from '@angular/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { ElementRef, ViewChild } from '@angular/core';
import showdown from 'showdown';
import ClassicEditor from "src/libs/ckeditor/ckeditor";
import $ from "jquery";
import moment from "moment";

@Component({
    selector: 'wiz-component-institutes-event-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/component.institutes.event.detail/view.scss */
.card-header h2 {
  font: normal normal bold 24px/27px NanumSquareOTF;
}

.page-title {
  font: normal normal bold 24px/27px NanumSquareOTF;
}

span {
  font: normal normal normal 16px SUIT;
}

.btn-secondary {
  width: 132px;
  height: 44px;
  border-radius: 10px;
}

.ck-read-only .ck-editor__editable {
  border: none !important;
  height: auto !important;
}

.none-div {
  height: 68px;
}

.btn-remove {
  width: 132px;
  height: 44px;
  background: #FF7676;
  color: white;
  border: none;
  border-radius: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 1510px;
}
.header button {
  width: 132px;
  height: 44px;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.btn-md {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  margin-left: auto;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: #6D6D6D;
}
.btn-md img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}

.btn-outline-blue {
  border-radius: 10px;
}

.text-muted {
  text-align: left;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
}

.workspace-list-header {
  width: 1570px;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  margin: 0 auto;
}

.page-header {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
}

.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal bold 18px/21px NanumSquareOTF;
  text-align: center;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 1230px;
}

.btn-blue {
  width: 132px;
  height: 44px;
  border-radius: 10px;
  margin-left: auto;
}

.btn-md {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  margin-left: auto;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: white;
  background: #5263FF;
  border-color: #5263FF;
}

.form-selectgroup-label {
  height: 44px;
  background: #F2F2F2;
  border-radius: 10px;
  color: #6D6D6D;
  display: flex !important;
  align-items: center;
  border: none;
}

.from-selectgroup-item {
  margin-right: 10px !important;
}

.edit-form {
  padding: 24px 32px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}

.card {
  max-width: 1440px;
  width: 90%;
  height: 85%;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  margin: 0 auto;
  border: none;
  overflow: auto;
}

.content {
  padding-bottom: 130px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content .content-container {
  padding: 64px auto;
  font-size: 18px;
}
.content h2 {
  margin: 0 auto;
  width: 1440px;
  text-align: center;
  font: normal normal bold 36px/41px NanumSquareOTF;
}
.content h4 {
  margin: 10px auto 10px;
  width: 1440px;
  color: #FF7676;
  text-align: center;
}
.content h3 {
  width: 1440px;
  text-align: left;
  border-bottom: solid 1px;
  font: normal normal bold 24px/27px NanumSquareOTF;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  background-color: white;
  text-align: left;
}

.table-last {
  width: 1440px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-last tbody tr td {
  height: 60px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  width: 1436px;
  height: 60px;
  display: flex;
  align-items: center;
  text-decoration: underline;
}

img {
  color: #5263FF;
}

.editor-container {
  width: 1280px;
}

.opt-area {
  display: flex;
  align-items: center;
  justify-content: center;
}
.opt-area .btn {
  margin: 0 4px;
  border-radius: 10px;
  width: 94px;
  height: 44px;
  background-color: #F2F2F2;
  font: normal normal normal 16px Montserrat;
  border: none;
}
.opt-area .btn-opt {
  padding: 12px 30px;
  border: none;
}
.opt-area .btn-opt:hover {
  color: #5263FF;
  border: 1px solid #5263FF;
  background-color: #cfd4fb;
}
.opt-area .btn-opt.active {
  background-color: #5263FF;
  color: #FFF;
  border: 1px solid #5263FF;
}

.wiz-form {
  margin: 0 auto;
  margin-top: 10px;
  max-width: 95%;
  background-color: white;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}
.wiz-form .wiz-form-label {
  background: #F6F6F6;
  font: normal normal bold 18px/21px NanumSquareOTF;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 1920px;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}
.season-page .header {
  border-radius: none;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-news {
  padding: 12px 30px;
  background: #5263FF 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: white;
  margin-left: 10px;
}

.btn-gray {
  padding: 12px 30px;
  margin-left: 10px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  background-color: #D1D1D1;
  border-radius: 10px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}`],
})
export class ComponentInstitutesEventDetailComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public status: string = 'submit';
    public level: string = 'low';
    @ViewChild('editor')
    public editorElement: ElementRef;
    public loading = false;
    @Output() loadUpdate = new EventEmitter<boolean>();
    @Input() post_id: any = '';
    @Input() institute_ns: any;
    public category: any = 'notice';
    public item: any = {
        user_name: '',
        user_role: '',
        title: '',
        content: '',
        reason: '',
        level: '',
        status: '',
        description: '',
        planstart: '',
        planend: '',
        todo: '',
        created: '',
        files: []
    };
    public reasonEditor: any;
    public descriptionEditor: any;

    public async ngOnInit() {
        await this.service.init();
        await this.init();
    }

    public async createEditor(selector: string, content: string) {
        const opt = {
            toolbar: {
                items: 'heading | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | insertTable | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true,
            },
            removePlugins: ['MediaEmbedToolbar', 'Markdown'],
            table: ClassicEditor.defaultConfig.table,
        };

        const editorInstance = await ClassicEditor.create(document.querySelector(selector), opt);

        if (content) {
            editorInstance.data.set(content);
        } else {
            editorInstance.data.set('');
        }

        const editorElement = editorInstance.ui.getEditableElement();

        if (selector === 'textarea#reason') {
            editorInstance.isReadOnly = true;
            editorInstance.ui.view.toolbar.element.style.display = 'none';

            editorElement.style.border = 'none';
            editorElement.style.height = 'auto';
            editorElement.style.setProperty('padding-bottom', '0', 'important');
        }

        if (selector === 'textarea#description' && (this.item.status != 'submit' || this.item.user_name == '관리자')) {
            editorInstance.isReadOnly = true;
            editorInstance.ui.view.toolbar.element.style.display = 'none';

            editorElement.style.border = 'none';
            editorElement.style.height = 'auto';
            editorElement.style.setProperty('padding-bottom', '0', 'important');

        }

        editorElement.style.display = 'block';

        return editorInstance;
    }

    public async statusChange() {
        return true
    }
    public async init() {

        if (this.post_id == 'new') {
            this.item = {
                level: 'low',
                status: 'submit',
                title: '',
                description: '',
                featured_image: '',
                todo: '',
                created: moment().format("YYYY-MM-DD"),
                planstart: moment().format("YYYY-MM-DD"),
                planend: moment().format("YYYY-MM-DD"),
            };
            const DESCRIPTION_ID = 'textarea#description';
            const descriptionContent = this.item.description || '';
            this.descriptionEditor = await this.createEditor(DESCRIPTION_ID, descriptionContent);
        } else {
            const { code, data } = await wiz.call("get", { id: this.post_id });
            this.item = data;
            this.item.planstart = moment(this.item.planstart).format("YYYY-MM-DD");
            this.item.planend = moment(this.item.planend).format("YYYY-MM-DD");

            const REASON_ID = 'textarea#reason';
            const reasonContent = this.item.reason || '-';
            this.reasonEditor = await this.createEditor(REASON_ID, reasonContent);

            const DESCRIPTION_ID = 'textarea#description';
            const descriptionContent = this.item.description || '';
            this.descriptionEditor = await this.createEditor(DESCRIPTION_ID, descriptionContent);
        }
        this.loading = true;
        await this.service.render();

    }

    public async switchTab(status: string) {
        this.status = status;
        await this.service.render();
    }


    public async removeImage() {
        this.item.featured_image = '';
        await this.service.render();
    }

    public async updateImage() {
        let img = await this.service.file.read({ type: 'image', accept: 'image/*', width: 512, quality: 1 });
        this.item.featured_image = img;
        await this.service.render();
    }

    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async update() {
        if (this.descriptionEditor) {
            this.item.description = this.descriptionEditor.getData();
        }
        this.item.user_name = this.service.auth.session.name
        this.item.user_role = this.service.auth.session.role
        if (this.item.title.length == 0) {
            await this.alert('제목을 입력해주세요.');
            return
        }
        if (this.item.description.length == 0) {
            await this.alert('내용을 입력해주세요.');
            return
        }

        let data: any = JSON.stringify(this.item);

        let res: any = await wiz.call("update", { data: data, ns: this.institute_ns });
        await this.alert('저장되었습니다', '', 'success', '확인');
        this.loadUpdate.emit(true)
        await this.service.render();
    }
    public desc() {
        let item: any = this.item;
        let text: any = item.description;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }
    public res() {
        let item: any = this.item;
        let text: any = item.reason;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }
    public async cancel() {
        let res: any = await this.alert("신청을 취소하시겠습니까?", "", "error", "확인", "돌아가기");
        if (!res) return;

        this.item.status = 'cancel'
        let data: any = JSON.stringify(this.item);
        await wiz.call("update", { data: data, ns: this.institute_ns });
        await this.alert('저장되었습니다', '', 'success', '확인');
        this.loadUpdate.emit(true)
        await this.service.render();
    }
    public async loadChange() {
        this.loadUpdate.emit(true)
    }


}

export default ComponentInstitutesEventDetailComponent;