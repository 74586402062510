import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.mail');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    selector: 'wiz-portal-kreonetadm-mail',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonetadm.mail/view.scss */
.card-header h2 {
  font: normal normal bold 24px/27px NanumSquareOTF;
  margin-top: auto;
}

.process {
  width: 1200px;
}

input,
textarea {
  text-align: left !important;
  vertical-align: top;
}

.page-title {
  font: normal normal bold 24px/27px NanumSquareOTF;
}

span {
  font: normal normal normal 16px SUIT;
}

.steps {
  font: normal normal normal 18px/21px NanumSquareOTF;
  width: 50%;
  margin: 0 auto;
}

.text-muted {
  text-align: left;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
}

select {
  width: 30%;
  height: 55px;
  border-radius: 10px !important;
  font: normal normal normal 16px/18px NanumSquareOTF;
  text-align: left !important;
}

.search {
  width: 30%;
}
.search .search-name {
  height: 55px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  border-radius: 10px;
}

.selected-row {
  background: #FAFAFA;
}

.check-box img {
  filter: invert(33%) sepia(28%) saturate(6221%) hue-rotate(226deg) brightness(104%) contrast(101%);
}

.empty-box img {
  filter: invert(100%) sepia(0%) saturate(5680%) hue-rotate(175deg) brightness(114%) contrast(64%);
}

textarea {
  vertical-align: top;
  text-align: left;
}

.wiz-form {
  margin: 0 auto;
  width: 100%;
  background-color: white;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
  width: 100%;
  padding: 0;
}
.wiz-form .wiz-form-label {
  font: normal normal normal 18px/21px NanumSquareOTF;
}

.form-control {
  border-radius: 10px;
}

.filebox {
  display: flex;
  justify-content: flex-end;
}

.content {
  padding: 0 25px;
  max-width: 1510px;
  min-width: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content h2 {
  margin: 0 auto;
  width: 1440px;
  text-align: center;
  font: normal normal bold 36px/41px NanumSquareOTF;
}
.content h4 {
  margin: 10px auto 10px;
  width: 1440px;
  color: #FF7676;
  text-align: center;
}
.content h3 {
  width: 1440px;
  text-align: left;
  border-bottom: solid 1px;
  font: normal normal bold 24px/27px NanumSquareOTF;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  background-color: white;
  text-align: left;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

#file {
  display: none;
}

.workspace-list-header {
  max-width: 1570px;
  width: 95%;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  margin: 0 auto;
}

.page-header {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.btn-search {
  width: 5%;
  height: 55px;
  background: #5263FF;
  margin-left: 5px;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: white;
  border-radius: 10px;
}

.btn-approve {
  width: 336px;
  height: 76px;
  background: #5263FF;
  margin: 0 auto;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: white;
  border-radius: 10px;
}

.btn-select {
  width: 200px;
  height: 60px;
  border: none;
  background: #DDEBFF;
  color: #3588FF;
  margin-left: auto;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
}

.search-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 99%;
}
@media (max-width: 1200px) {
  .search-box {
    width: 97%;
  }
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 1230px;
}

.btn-blue {
  width: 132px;
  height: 100%;
  text-align: center;
  border-radius: 10px;
  background: none;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #5D5D5D;
  box-shadow: none;
}
.btn-blue:hover {
  background: none;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: #FE5A33;
  background: rgba(254, 90, 51, 0.04);
  border-color: #FE5A33;
}

.edit-form {
  padding: 24px 32px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

.container {
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
}

.card {
  max-width: 1570px;
  height: 530px;
  width: 95%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
  overflow: auto;
}
.card h2 {
  width: 30%;
  height: 100%;
}

.card-search {
  max-width: 1570px;
  width: 95%;
  height: 130px;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search h2 {
  width: 30%;
  height: 100%;
}

.card-search-list {
  max-width: 1570px;
  width: 95%;
  height: 380px;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-search-list h2 {
  width: 30%;
  height: 100%;
}

.card-select-list {
  max-width: 1570px;
  width: 95%;
  height: 320px;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  background: white;
  border-radius: 10px;
  border: none;
}
.card-select-list h2 {
  width: 30%;
  height: 100%;
}

.row {
  padding: 0.5rem 2rem;
  margin: 0 auto;
  display: flex;
  max-width: 1570px;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
}

.row-user {
  padding: 0.5rem 1.5rem;
  padding-top: 1rem;
  margin: 0 auto;
  height: 50px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.table-responsive {
  padding: 0 1rem;
  max-height: 80%;
  overflow-y: auto;
}

.table-last {
  max-width: 1509px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  font: normal normal bold 17px/19px NanumSquareOTF;
  background: none;
  vertical-align: middle;
  color: #000000;
}
.table-last tbody tr {
  width: 1440px;
  height: 50px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 30px;
  padding: 0;
  font: normal normal normal 17px/19px NanumSquareOTF;
}`],
})
export class PortalKreonetadmMailComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( FormBuilder)  private fb: FormBuilder) {
        this.form = this.fb.group({
            institution: [''],
            role: [''],
            name: ['']
        });
    }
    public emailForm: any = {
        title: '',
        content: '',
        files: []
    };

    public fd = new FormData();
    public loading = true;
    public categoryMap: any = { notice: '공지사항', report: '보고서', article: '보도자료' };
    public category: any = 'notice';
    public users: any = [];
    public loaded: boolean = false;
    public allSelected: boolean = false;
    private status = "default";
    public selected_status = false;
    public selectedUsers = []

    public roles = [
        '-',
        '관리책임자',
        '전산담당자',
        '연회비담당자',
        '연구책임자',
        '실무연구자'
    ];
    public pagenation = {
        start: 1,
        end: 1,
        current: 1,
    };
    public form: FormGroup;

    public institutions = ['한국과학기술정보연구원', '기초과학연구원', '한국전기연구원', '한국생명공학연구원', '주식회사 시즌', '질병관리청 국립보건연구원'];
    public employeesByInstitution: any = {};
    public selectedInstitution = '소속기관';
    public selectedRole = '역할';
    public selectedName = '';
    public searchedUsers = [];
    public emailList = [];
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
        await this.service.render();
    }

    public async initializeEmployeesByInstitution() {
        this.institutions.forEach(institution => {
            this.employeesByInstitution[institution] = this.users.filter(user => user.institute === institution);
        });
    }
    public async onInstitutionChange() {
        this.selectedRole = '역할';
        this.selectedName = '';
        await this.filterUsers();
    }

    public async onRoleChange() {
        this.selectedName = '';
        await this.filterUsers();
    }

    public async onNameChange() {
        await this.filterUsers();
    }

    public async fileUpload(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i]
            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.emailForm.files.push(file.filepath);
        }
        await this.service.render();
    }

    public async removeFile(id, index) {
        if (id == 'post') {
            this.post.files.splice(index, 1)
        }

        for (let i = 0; i < this.comments.length; i++) {
            let comment = this.comments[i]
            if (comment['id'] == id) {
                comment.files.splice(index, 1);
                this.post.files = comment.files
                await this.upload();
                await this.service.render();
            }
        }
    }
    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    
    public async filterUsers() {
        this.searchedUsers = this.users.filter(user => {
            return (
                (this.selectedInstitution === '소속기관' || user.institute === this.selectedInstitution) &&
                (this.selectedRole === '역할' || user.ins_role === this.selectedRole) &&
                (this.selectedName === '-' || user.name.includes(this.selectedName))
            );
        });
    }

    public async load() {
        this.loaded = false;
        const { code, data } = await wiz.call("search", { page: this.pagenation.page, status: this.status });
        if (code != 200) return;
        this.users = data.rows;
        await this.initializeEmployeesByInstitution();
        await this.filterUsers();
        this.loading = true;
        this.pagenation.start = Math.floor((this.pagenation.page - 1) / 10) * 10 + 1;
        this.pagenation.end = data.lastpage;
        this.loaded = true;
        await this.service.render();
    }

    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }


    private async personSelect(email: string) {
        if (email === 'all') {
            this.selected_status = !this.selected_status;
            if (this.selected_status) {
                this.searchedUsers.forEach(user => user.selected = true);
                this.selectedUsers = [...this.searchedUsers];
            }
            else {
                this.users.forEach(user => user.selected = false);
                this.selectedUsers = [];
            }
        } else if (email == 'selectAll') {
            this.selectedUsers.forEach(user => user.selected = false);
            this.selected_status = false;
            this.selectedUsers = [];
        } else {
            let user = this.users.find(user => user.email === email);

            if (!user) {
                return;
            }

            user.selected = !user.selected;

            if (user.selected) {
                if (!this.selectedUsers.some(selectedUser => selectedUser.email === user.email)) {
                    this.selectedUsers.push(user);
                }
            } else {
                let selectedIndex = this.selectedUsers.findIndex(selectedUser => selectedUser.email === user.email);
                if (selectedIndex !== -1) {
                    this.selectedUsers.splice(selectedIndex, 1);
                }
            }
        }

        await this.service.render();
    }

    public async emailAuthentication() {
        this.fd.append('emails', JSON.stringify(this.emailList))
        this.fd.append('data', JSON.stringify(this.emailForm))
        if (this.emailList.length == 0) {
            await this.alert('수신자를 선택해주세요');
            return;
        }
        if (this.emailForm.title.length == 0) {
            await this.alert('메일 제목을 입력해주세요');
            return;
        }
        if (this.emailForm.content.length == 0) {
            await this.alert('메일 내용을 입력해주세요');
            return;
        }

        let url = wiz.url('emailAuthentication')
        const { code, data } = await this.service.file.upload(url, this.fd);
        if (code == 200) {
            await this.alert('이메일이 발송되었습니다', 'success');
            this.selectedUsers = [];
            this.searchedUsers = [];
            this.emailForm = { title: '', content: '', files: [] };
            this.fd = new FormData();

        } else {
            await this.alert('이메일 발송중 오류가 발생했습니다');
        }

        await this.load();
    }
    public async getEmails() {
        this.emailList = this.selectedUsers.map(user => user.email);
    }

    public async loadChange() {
        this.loading = !this.loading;
        await this.service.render();
    }

}

export default PortalKreonetadmMailComponent;