import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.page.admin.info');
import { OnInit, OnDestroy, DoCheck } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-page-admin-info',
template: templateSource || '',
    styles: [``],
})
export class PagePageAdminInfoComponent implements OnInit, OnDestroy, DoCheck {
    public PAGE: any;
    public ITEM: any;
    public ID: any;

    constructor(@Inject( Service) public service: Service) {
        if (!WizRoute.segment.page)
            return service.href("/admin/info/users");
        this.PAGE = WizRoute.segment.page;
        this.ITEM = WizRoute.segment.item;
        this.CATEGORY = WizRoute.segment.category;
        this.ID = WizRoute.segment.id;


    }

    public async ngDoCheck() {
        if (this.PAGE != WizRoute.segment.page) {
            this.PAGE = WizRoute.segment.page;
            await this.service.render();
        }

        if (this.ITEM != WizRoute.segment.item) {
            this.ITEM = WizRoute.segment.item;
            await this.service.render();
        }

        if (this.CATEGORY != WizRoute.segment.category) {
            this.CATEGORY = WizRoute.segment.category;
            await this.service.render();
        }

        if (this.ID != WizRoute.segment.id) {
            this.ID = WizRoute.segment.id;
            await this.service.render();
        }
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        await this.service.render();
    }

}

export default PagePageAdminInfoComponent;