import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.community.view');
import { OnInit, ElementRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Service } from "src/libs/portal/season/service";

import ClassicEditor from "src/libs/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-community-view',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.community.view/view.scss */
.info-form.first-child {
  border-top: 2px solid #000000;
}

.info-form {
  padding: 12px 0;
  border-bottom: 1px solid rgba(110, 117, 130, 0.2);
}

.row {
  --tblr-gutter-x: 1rem;
  --tblr-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--tblr-gutter-y));
  margin-right: calc(-0.5 * var(--tblr-gutter-x));
  margin-left: calc(-0.5 * var(--tblr-gutter-x));
}

.comment-info {
  align-content: right;
}`],
})
export class PortalCommunityViewComponent implements OnInit {
    private view = {
        parent_id: "",
        priority: "",
        title: "",
        created: "",
        content: ""
    };
    private login = {};
    private user = {};
    private category = "";
    private post_id;

    @ViewChild('editoro')
    public editorElement: ElementRef;
    public editor: any;

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,@Inject( ActivatedRoute)         private route: ActivatedRoute,    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.buildEditor();
        await this.service.render();
    }

    public async alert(message: string, status: string = 'error', cancel: any = false, action: string = '확인') {
        return await this.service.alert.show({
            title: "",
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    private async load() {
        this.post_id = WizRoute.segment.id;
        this.category = WizRoute.segment.category;

        if (this.post_id == 'new') {
            await this.alert('잘못된 접근입니다.');
            this.service.href(['/community/list']);
        }

        let { code, data } = await wiz.call('load', { id: this.post_id });
        if (code != 200) {
            await this.alert('Error!');
        }
        const { view, user, login } = data;
        this.view = view;
        if (this.view.files) this.view.files = JSON.parse(this.view.files.replace(/'/g, '"'));
        this.user = user;
        this.login = login;

        await this.service.render();
    }

    public async buildEditor() {
        let editor = this.editor = await ClassicEditor.create(this.editorElement.nativeElement, {
            toolbar: {
                items: [
                    'undo', 'redo',
                    '|', 'heading',
                    '|', 'alignment:left', 'alignment:center', 'alignment:right', 'alignment:justify',
                    '|', 'fontColor', 'fontBackgroundColor', 'highlight',
                    '|', 'bold', 'italic', 'strikethrough', 'underline', 'code',
                    '|', 'bulletedList', 'numberedList', 'todoList', 'outdent', 'indent',
                    '|', 'link', 'uploadImage', 'insertTable', 'blockQuote', 'codeBlock'
                ],
                shouldNotGroupWhenFull: true
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                ]
            },
            link: {
                addTargetToExternalLinks: true,
                defaultProtocol: 'https://'
            },
            removePlugins: ["MediaEmbedToolbar", "Markdown"],
            table: ClassicEditor.defaultConfig.table,
            simpleUpload: {
                uploadUrl: '/file/community/upload/' + this.post_id
            }
        });

        const toolbarElement = editor.ui.view.toolbar.element;
        toolbarElement.style.display = 'none';
        editor.isReadOnly = true;
        this.editor.data.set(this.view.content);
    }
}

export default PortalCommunityViewComponent;