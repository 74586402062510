import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.admin.notice.detail');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import { Location } from '@angular/common'

@Component({
    selector: 'wiz-portal-apply-admin-notice-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.apply.admin.notice.detail/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.content {
  padding-top: 64px;
  padding-bottom: 176px;
}
.content h2 {
  margin: 0 auto;
  width: 1440px;
  text-align: center;
}
.content h4 {
  margin: 10px auto 10px;
  width: 1440px;
  color: #FF7676;
  text-align: center;
}
.content h3 {
  margin: 0 auto;
  width: 1440px;
  text-align: center;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  text-align: left;
}

.wiz-form {
  margin: 0 auto;
  width: 1440px;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-news {
  padding: 12px 30px;
  background: #5263FF 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: white;
  margin-left: 10px;
}

.btn-gray {
  padding: 12px 30px;
  margin-left: 10px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  background-color: #D1D1D1;
  border-radius: 10px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: url("/assets/portal/apply/featured/banner.png");
  min-height: 378px;
  max-height: 378px;
  padding-bottom: 12px;
  display: flex;
  flex-direction: row;
}
.header .text-container {
  width: 1440px;
  margin: 0 auto;
}

.content {
  padding-top: 64px;
  padding-bottom: 176px;
  min-height: 100%;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
}

.content-body {
  max-width: 860px;
  margin: 0 auto;
}`],
})
export class PortalApplyAdminNoticeDetailComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  public location: Location) { }

    @Input() post_id: any = "new";
    public item: any;
    public user: any;
    public data: any = {};
    public fd = new FormData();
    public subForm: any = {
        user_id: '',
        title: '',
        content: '',
        email: '',
        institute: '',
        name: '',
        tel: '',
        files: []
    };
    public tab: string = "content";
    public user_email: string;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }
    public async init() {
        this.subForm.user_id = WizRoute.segment.id;
    }

    public async load() {
        this.user_email = this.service.auth.session.email

        const { code, data } = await wiz.call("get", { id: this.post_id, email: this.user_email });
        this.item = data.item;
        this.user = data.user;
        await this.service.render();

    }


    public async submit() {
        this.subForm.email = this.user.email;
        this.subForm.institute = this.user.institute;
        this.subForm.name = this.user.name;
        this.subForm.tel = this.user.tel;

        let subForm = this.subForm;
        this.fd.append("data", JSON.stringify(subForm));

        let url = wiz.url('submit');

        const { code, data } = await this.service.file.upload(url, this.fd);

        if (code === 200) {
            location.href = '/dashboard';
        }
        else alert('오류가 발생했습니다.');

        await this.service.render();
    }

    public async upload(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i];
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.subForm.files.push(file.filepath);
        }
        await this.service.render();
    }

    public desc() {
        let item: any = this.item;
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }

    public back() {
        this.location.back();
    }

    public async tabChange(tab: string) {
        this.tab = tab;
        await this.service.render();
    }


}

export default PortalApplyAdminNoticeDetailComponent;