import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.admin.list.detail');
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';

@Component({
    selector: 'wiz-portal-apply-admin-list-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.apply.admin.list.detail/view.scss */
.card-header h2 {
  font: normal normal bold 24px/27px NanumSquareOTF;
  margin-top: auto;
}

.btn-outline-blue {
  border-radius: 10px;
}

.d-print-none {
  height: 39px;
}

.page-title {
  font: normal normal bold 24px/27px NanumSquareOTF;
  height: 39px;
}

span {
  font: normal normal normal 16px SUIT;
}

img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 5%;
  height: 7%;
}

hr {
  margin: 0;
  padding: 30 0 0 0;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  width: 98%;
}
.header button {
  width: 132px;
  height: 44px;
}

.buttons {
  display: flex;
  flex-direction: row;
}
.buttons .btn-blue {
  width: 132px;
  height: 44px;
  border-radius: 10px;
}

border-line {
  width: 98%;
  margin: 0 auto;
}

.text-muted {
  text-align: left;
  font: normal normal normal 18px/21px NanumSquareOTF;
  color: #5D5D5D;
}

.workspace-list-header {
  max-width: 1570px;
  width: 95%;
  height: 80px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  margin: 0 auto;
}

.page-header {
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  text-align: left;
}

.form-control {
  text-align: left;
}

.form-group {
  padding: 15px 0px;
  border-top: 1px solid #E3E3E3;
}
.form-group .form-label {
  display: flex;
  align-items: center;
  font: normal normal bold 14px/18px SUIT;
  margin-bottom: 0px;
}
.form-group .form-selectgroup-label {
  padding: 7px 16px;
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal bold 18px/21px NanumSquareOTF;
  text-align: center;
}

.col-auto {
  margin: 0 auto;
  width: 700px;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 1230px;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.btn-approve {
  width: 336px;
  height: 76px;
  background: #DDEBFF;
  margin: auto;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: #3588FF;
  border-radius: 10px;
}

.btn-red {
  width: 336px;
  height: 76px;
  background: #FFE6E6;
  font: normal normal bold 18px/21px NanumSquareOTF;
  color: #D32F2F;
  border-radius: 10px;
}

.form-selectgroup-input:checked + .form-selectgroup-label {
  z-index: 1;
  color: #FE5A33;
  background: rgba(254, 90, 51, 0.04);
  border-color: #FE5A33;
}

.edit-form {
  padding: 24px 32px;
}

.btn-season {
  background-color: #FE5A33;
  border: 1px solid #FE5A33;
  color: white;
}
.btn-season:hover {
  background-color: #fff;
  border: 1px solid #FE5A33;
  color: #FE5A33;
}

.page-icon.back-btn {
  cursor: pointer;
  background-color: #FE5A33 !important;
}

input,
textarea {
  text-align: left !important;
  border-radius: 0 !important;
}

.card {
  max-width: 1570px;
  width: 95%;
  min-height: 93%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
}

.card2 {
  max-width: 1570px;
  width: 95%;
  padding: 0.5rem;
  min-height: 35%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
  background: white;
}

.content {
  width: 98%;
  min-height: 93%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  align-items: center;
}
.content h3 {
  margin: 0 auto;
  width: 98%;
  text-align: left;
  font: normal normal bold 24px/27px NanumSquareOTF;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}
.content .content-body {
  min-height: 850px;
  background-color: white;
  text-align: left;
}

.table-last {
  width: 1440px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last tbody tr {
  width: 1440px;
  height: 80px;
  border-bottom: solid 0.5px #D1D1D1;
}
.table-last tbody tr td {
  height: 60px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
li a {
  text-decoration: underline;
}
li img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

.wiz-form {
  margin: 0 auto;
  margin-top: 10px;
  width: 98%;
  background-color: white;
  font: normal normal normal 18px/21px NanumSquareOTF;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}
.wiz-form .wiz-form-label {
  font: normal normal bold 18px/21px NanumSquareOTF;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  width: 1920px;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}
.season-page .header {
  border-radius: none;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

.btn-news {
  padding: 12px 30px;
  background: #5263FF 0% 0% no-repeat padding-box;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: white;
  margin-left: 10px;
}

.btn-gray {
  padding: 12px 30px;
  margin-left: 10px;
  font: normal normal normal 16px/18px NanumSquareOTF;
  color: #000000;
  background-color: #D1D1D1;
  border-radius: 10px;
}

.var {
  align-items: center;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}`],
})
export class PortalApplyAdminListDetailComponent implements OnInit {

    public categoryMap: any = { notice: '공지사항', report: '보고서', article: '보도자료' };
    public category: any = 'notice';
    public items: any = [];
    public status = '';
    public notice = {
        title: '',
        planstart: '',
        planend: ''
    };
    @Input() submit_id: any;
    public item = {
        id: '',
        user_id: '',
        application_id: '',
        comment_id: '',
        title: '',
        content: '',
        name: '',
        email: '',
        tel: '',
        institute: '',
        files: [],
        status: '',
        status_re: ''
    };
    public tab: string;
    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,@Inject( Router)         private router: Router,    ) {
    }


    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/authenticate');
        await this.load();
    }


    public async load() {
        const { code, data } = await wiz.call("get", { 'id': this.submit_id });
        let { item, submit_notice } = data;
        this.item = item;
        this.item.files = JSON.parse(data.item.files.replace(/'/g, '"'));
        this.status = item.status;
        this.notice = submit_notice;
        await this.service.render();

    }
    public async update() {
        if (this.item.status == 'reject' && this.item.status_re.length == 0) {
            await this.alert('거절 사유를 입력해주세요.')
            return
        }
        const { code, data } = await wiz.call("update", { 'id': this.submit_id, 'status': this.item.status, 'status_re': this.item.status_re });
        if (code == 200) { await this.alert('저장되었습니다', '', 'success', '확인'); }
        this.service.href(`/admin/research/list`);
        await this.service.render();
    }

    public async tabChange(tab: string) {
        this.tab = tab;
        this.item.status = this.tab
        await this.service.render();
    }

}

export default PortalApplyAdminListDetailComponent;