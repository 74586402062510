import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.institutes.docs');
import { OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Service } from "src/libs/portal/season/service";
import { Menu } from "src/libs/menu";

@Component({
    selector: 'wiz-page-institutes-docs',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.institutes.docs/view.scss */
.content {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}

tfoot {
  background-color: #F4F9FE;
}

.title {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  padding: 1rem 2rem;
}
.row .form-select {
  border: 1px solid #929DAB;
  border-radius: 10px;
  width: 132px;
  height: 44px;
}

.disabled {
  filter: grayscale(1);
  opacity: 0.3;
  cursor: default !important;
}

.btn-remove {
  width: 20px;
  height: 20px;
  background: #FF7676;
  color: white;
  border: none;
  border-radius: 10px;
}

.pagenation {
  margin-top: 50px;
}

.search {
  background: white;
}
.search i {
  width: 18px;
  height: 18px;
  color: white;
}
.search .form-control {
  border-radius: 10px 0 0 10px;
  width: 349px;
  height: 44px;
  border: none;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  padding-left: 25px;
  z-index: 3;
  color: var(--unnamed-color-6d6d6d);
  text-align: left;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-nanumsquareotf);
  box-shadow: none;
}
.search .btn-search {
  width: 44px;
  height: 44px;
  border: none;
  background: #5263FF 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  z-index: 3;
}

.workspace {
  padding: 0 24px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 999;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: auto;
}

.work-container {
  display: table;
  width: max-content;
  height: 100%;
  overflow: hidden;
}
.work-container .work-group {
  display: table-cell;
  width: 320px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid var(--wc-border);
}
.work-container .work-group .work-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-top: 2px;
}
.work-container .work-group .work-header {
  display: flex;
  height: 46px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid var(--wc-border);
}
.work-container .work-group .work-header input {
  font-size: 16px;
  font-family: "main-eb";
}
.work-container .work-group .work-header .btn {
  min-width: 24px;
}
.work-container .work-group .work-body {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
}
@media (max-width: 768px) {
  .work-container .work-group .work-body {
    padding: 0 32px;
  }
}
.work-container .btn-action-move {
  cursor: grab !important;
}
.work-container .label-container {
  height: 100%;
  display: table;
  width: max-content;
  overflow: hidden;
}
.work-container .issue-container {
  min-height: 160px;
}

.empty img {
  width: 80%;
  max-width: 240px;
}

.issue-item {
  border-radius: 16px;
  margin-bottom: 12px;
}
.issue-item:first-child {
  margin-top: 12px;
}
@media (max-width: 768px) {
  .issue-item {
    margin-bottom: 16px;
  }
}

.status-items {
  font-size: 13px;
}

.issue-layout {
  position: relative;
  width: 100%;
  height: 100%;
}

.scroll-left {
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 1000;
  font-size: 24px;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .scroll-left {
    display: none;
  }
}
.scroll-left:hover {
  background-color: var(--wc-border);
}

.scroll-right {
  position: absolute;
  top: 49%;
  right: 0;
  z-index: 1000;
  font-size: 24px;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .scroll-right {
    display: none;
  }
}
.scroll-right:hover {
  background-color: var(--wc-border);
}

.card {
  max-width: 1440px;
  width: 90%;
  height: 85%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
}

.row {
  padding: 1rem 2rem;
}
.row .form-select {
  border: 1px solid #5263ff;
  border-radius: 10px;
  width: 132px;
  height: 44px;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.table-responsive {
  padding: 0 1rem;
}

.table-last {
  max-width: 1380px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  font: normal normal bold 17px/19px NanumSquareOTF;
  background: none;
  vertical-align: middle;
  color: #000000;
}
.table-last tbody tr {
  width: 1380px;
  height: 70px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr td {
  height: 60px;
  font: normal normal normal 18px/21px NanumSquareOTF;
}`],
})
export class PageInstitutesDocsComponent implements OnInit {
    private empty: boolean = false;
    public mode: string = "view";
    public data: any = {
        info: {}
    }
    public doc_id: string = '';

    public institute_ns: string = '';
    public loading = true;
    public loaded: boolean = false;
    public list = [];
    private search_word = "";
    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };

    private num;
    private query;
    public readOnly: boolean = true;
    constructor(@Inject( ActivatedRoute)         public route: ActivatedRoute,@Inject( Service)         public service: Service,@Inject( Menu)         public menu: Menu,    ) {
        this.institute_ns = WizRoute.segment.id
    }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
        await this.service.render();
    }
    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.");
        }
        this.pagenation.page = 1;
        await this.load();
    }

    public async load() {
        this.loaded = false;
        this.search.ns = this.institute_ns
        const { code, data } = await wiz.call("load", { page: this.pagenation.page, ns: this.institute_ns, text: this.search_word });
        if (code !== 200) {
            return;
        }
        const { lastpage, list, info } = data;
        this.data.info = info;
        this.list = list;
        this.pagenation.start = Math.floor((this.pagenation.page - 1) / 10) * 10 + 1;
        this.pagenation.end = lastpage;
        this.loaded = true;
        this.service.institute = this.data;
        await this.service.render();

    }

    public async upload() {
        let files = await this.service.file.select({ accept: '.pdf,.hwp,.hwpx,.doc,.docx,.xls,.xlsx,.ppt,.pptx', multiple: true });

        let fd = new FormData();
        let filepath = [];

        for (let i = 0; i < files.length; i++) {
            let file = files[i]
            if (!file.filepath) file.filepath = file.name;
            const extension = file.name.split(".").pop();
            fd.append('file[]', file);
            filepath.push(file.filepath);
        }
        fd.append("ns", JSON.stringify({ institute_ns: this.institute_ns }))
        fd.append("filepath", JSON.stringify(filepath));

        let url = wiz.url('upload');
        try {
            const { code } = await this.service.file.upload(url, fd);
            await this.service.render();
            if (code == 200) alert("업로드를 완료했습니다.");
            await this.load(1)
        } catch (e) {
            console.log(e)
            alert("업로드중 에러발생. 파일을 나눠서 다시 업로드해주세요.");
        }
    }

    public async preview(item) {
        let download = wiz.url('pdf?id=' + item.id + "&title=" + item.title)
        let res = await fetch(download, {
            method: "post",
        });

        const blob = await res.blob();
        const a = document.createElement('a');
        a.style.display = 'none';
        const download_url = window.URL.createObjectURL(blob);
        a.href = download_url;
        a.target = "_blank";

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(download_url);
    }

    public async download(item, type) {
        if (!item[type] && !item[type + "x"]) return;
        if (await this.service.auth.allow(false)) {
            alert("로그인 후 이용해주세요.");
            return 0;
        }
        type = item[type] ? type : type + "x";
        let download = wiz.url('download?id=' + item.id + "&title=" + item.title + "&type=" + type)
        window.open(download, '_blank');
    }

    private handleClick(item) {
        this.service.href([`/main/detail/${item.id}`, this.query])
    }

    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }

    private detail(id: string) {
        this.doc_id = id;
        this.loading = false;
    }
}

export default PageInstitutesDocsComponent;