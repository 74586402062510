import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.loading');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-component-loading',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/component.loading/view.scss */
/* src/app/component.loading/view.scss: no such file or directory */`],
})
export class ComponentLoadingComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() { }
}

export default ComponentLoadingComponent;