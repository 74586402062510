import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.season.tab');
import { OnInit, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { ViewContainerRef } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-season-tab',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.season.tab/view.scss */
.view-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.view-container .view-header {
  width: 100%;
  display: flex;
  background-color: var(--wc-light);
}
.view-container .view-header .view-action {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  cursor: pointer;
}
.view-container .view-header .view-tab {
  display: flex;
  width: 140px;
  padding: 2px 4px;
  border-right: 1px solid #fff;
  cursor: pointer;
  color: var(--wc-text);
}
.view-container .view-header .view-tab span {
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}
.view-container .view-header .view-tab .view-tab-close {
  display: none;
  margin-left: auto;
  margin-right: 4px;
  justify-content: center;
  align-items: center;
  color: var(--wc-text);
}
.view-container .view-header .view-tab.relative, .view-container .view-header .view-tab:hover {
  background-color: var(--wc-gray);
}
.view-container .view-header .view-tab:hover .view-tab-close {
  display: flex;
}
.view-container .view-header .view-tab.active {
  background-color: #fff;
}
.view-container .view-header .view-tab.active .view-tab-close {
  display: flex;
}
.view-container .view-body {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  background: #fff;
}
@media (max-width: 768px) {
  .view-container .view-tab {
    padding: 4px 6px;
  }
  .view-container .view-tab .view-tab-close {
    font-size: 16px;
  }
}`],
})
export class PortalSeasonTabComponent implements OnInit {
    @Input() config: any = {};

    public tabs: any = [];

    @ViewChild('editor')
    public element: ElementRef;

    constructor(@Inject( Service)         public service: Service,@Inject( ViewContainerRef    )         public viewContainerRef: ViewContainerRef    ) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        this.config.open = this.open.bind(this);
        this.config.find = this.find.bind(this);
        this.config.list = this.list.bind(this);
        this.config.findByIndex = this.findByIndex.bind(this);
        this.config.selected = {};
        if (this.config.onLoad) await this.config.onLoad();
    }

    public sortableOption: any = {
        animation: 0,
        handle: '.view-tab'
    };

    public find(tabId: string, view: any = null) {
        for (let i = 0; i < this.tabs.length; i++)
            if (this.tabs[i].id == tabId)
                if (!view || view == this.tabs[i].view)
                    return this.tabs[i];
        return null;
    }

    public findByIndex(index: number) {
        return this.tabs[index];
    }

    public list() {
        return this.tabs;
    }

    public async open(tab: any, reopen: boolean = false) {
        if (!tab.id) return;
        if (!tab.view) return;
        if (!tab.title) tab.title = id;
        let self = this;

        if (!reopen)
            for (let i = 0; i < self.tabs.length; i++) {
                if (self.tabs[i].id == tab.id && self.tabs[i].view == tab.view) {
                    tab = self.tabs[i];
                    await tab.open();
                    return tab;
                }
            }

        tab.container = this;

        tab.updateTitle = async (_title: string) => {
            tab.title = _title;
            await self.service.render();
        }

        tab.close = async () => {
            let index = self.tabs.indexOf(tab);
            self.tabs.remove(tab);
            if (self.config.selected.id == tab.id) {
                self.element.nativeElement.innerHTML = "";
                self.config.selected = {};
            }
            await self.service.render();
            if (self.config.on) await self.config.on("close");
            if (tab.onClose) await tab.onClose("close");
            if (tab.ref) await tab.ref.destroy();

            let alivetab: any = this.find(tab.id);
            if (alivetab) return await alivetab.open();
            if (self.tabs[index]) return await self.tabs[index].open();
            if (self.tabs[index - 1]) return await self.tabs[index - 1].open();
        }

        tab.open = async () => {
            if (self.config.selected == tab) return;
            let preselected: any = {};
            if (self.config.selected != tab)
                preselected = self.config.selected;
            self.config.selected = tab;
            await self.service.render();

            if (!tab.ref) {
                const ref = self.viewContainerRef.createComponent<NodeComponent>(tab.view);
                ref.instance.tab = tab;
                tab.ref = ref;
            }

            if (tab.onCreatedRef) await tab.onCreatedRef(tab.ref);

            let editorElement = tab.ref.location.nativeElement;
            self.element.nativeElement.innerHTML = "";
            self.element.nativeElement.append(editorElement);
            if (self.config.on) await self.config.on("open");
            await self.service.render();
            if (tab.onOpen) await tab.onOpen("open");

            if (preselected.ref && preselected.ref.instance.wizOnTabHide) await preselected.ref.instance.wizOnTabHide();
            if (tab.ref.instance.wizOnTabInit) await tab.ref.instance.wizOnTabInit();
        }

        self.tabs.push(tab);
        await tab.open();
        return tab;
    }
}

export default PortalSeasonTabComponent;