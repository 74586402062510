import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.institutes');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-page-institutes',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.institutes/view.scss */
.container {
  height: max-content;
}

.card-table .table {
  position: absolute;
}
.card-table .table th {
  font-size: 14px;
  background: #EFF4FE;
  padding: 8px 12px;
}
.card-table .table thead {
  position: sticky;
  top: 0;
}
.card-table .table tbody {
  background: #fff;
}
.card-table .table tbody tr {
  cursor: pointer;
}
.card-table .table tbody tr:hover {
  background: var(--wc-background);
}
.card-table .table tbody td {
  white-space: nowrap;
}
.card-table .table tbody .user-status-icon {
  display: inline-block;
  width: 20px;
  text-align: center;
  line-height: 1;
  padding: 4px 0;
  font-size: 12px;
  border-radius: 10px;
  background: var(--wc-blue);
  color: #fff;
}
.card-table .table tbody .btn i {
  width: 16px;
}

.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 640px;
  background: #fff;
  border-left: 1px solid var(--wc-border);
}
.sidebar .card {
  border-radius: 0;
  border: none;
}
.sidebar .card > * {
  border-radius: 0;
}`],
})
export class PageInstitutesComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.service.loading.show();
        await this.load();
        await this.service.loading.hide();
    }

    public loaded: boolean = false;
    public list: any = [];
    public data: any = {};

    public search: any = {
        page: 1,
        text: ''
    };

    public pagenation: any = {
        end: -1,
        start: -1,
    };

    public async alert(message: string, status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: '',
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }

    public async load(page: number = 1) {
        this.loaded = false;
        let { code, data } = await wiz.call("list", { page: page, text: this.search.text });
        if (code != 200) return;
        let { rows, lastpage } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.search.page = page;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;

        if (page == 1 && this.list.length == 1) {
            this.service.href(`/institutes/info/${this.list[0].namespace}`);
            return;
        }

        this.loaded = true;
        await this.service.render();
    }

    public async pageMove(page: number) {
        await this.load(page);
    }
}

export default PageInstitutesComponent;