import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonetadm.weathermap');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-kreonetadm-weathermap',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonetadm.weathermap/view.scss */
.map-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 18px 24px;
  background: var(--wc-background);
  border-radius: 24px;
}

.widget-area {
  width: 420px;
  max-width: 100%;
  padding-left: 24px;
  height: 100%;
  overflow: auto;
}

.map-area {
  flex: auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--wc-background-dark);
  padding: 24px;
  border-radius: 12px;
  overflow: auto;
}
.map-area .map {
  flex: auto;
  width: 100%;
  height: 100%;
}

.card {
  margin-bottom: 24px;
}
.card .card-header {
  padding: 8px 16px;
}
.card .card-body {
  padding: 12px 24px;
  border-top: none !important;
}
.card .location {
  overflow: auto;
}

.input-icon .form-control.form-control-rounded {
  border: 1px solid var(--wc-blue);
  border-radius: 8px;
}
.input-icon .input-icon-addon {
  color: var(--wc-blue);
}

.colors .color-container {
  margin-bottom: 4px;
}
.colors .color {
  width: 20px;
  height: 20px;
  border-radius: 4px;
}
.colors input {
  width: 80px;
}

.btn.btn-sm {
  min-width: 32px;
}`],
})
export class PortalKreonetadmWeathermapComponent implements OnInit {
    constructor(@Inject( Service) public service: Service) { }
    public async ngOnInit() {
        this.service.loading.show();
        await this.service.init();
        await this.service.auth.allow('admin', '/');
        this.initialized = true;
        await this.service.render(1000);
        this.service.loading.hide();
    }

    public selected: any = null;
    public selectedLine: any = null;
    public initialized: boolean = false;

    public deviceSelectedItem: any = null;

    public async deviceSelect(inout: string, item: any) {
        this.deviceSelectedItem = item;
        if (inout == 'in')
            this.deviceSelectedItem.label = this.selected.name + '←' + item.info.name;
        else
            this.deviceSelectedItem.label = this.selected.name + '→' + item.info.name;
        if (!this.deviceSelectedItem.options.devices) this.deviceSelectedItem.options.devices = [];
        await this.service.render();
    }

    public count(item: any) {
        if (!item.options.devices) return 0;
        return item.options.devices.length;
    }

    public async deviceSelected(data: any) {
        this.deviceSelectedItem.options.devices = data;
        let item = JSON.parse(JSON.stringify(this.deviceSelectedItem));
        await wiz.call("updateLineOption", { id: item.id, options: JSON.stringify(item.options) });
        this.deviceSelectedItem = null;
        await this.service.render();
    }

    public async deviceCanceled() {
        this.deviceSelectedItem = null;
        await this.service.render();
    }

    public event: any = {
        data: {},
        selected: async (location) => {
            this.selected = location;
            const { code, data } = await wiz.call("get", { location: location.id });
            this.selectedLine = data;
            await this.service.render();
        }
    };

}

export default PortalKreonetadmWeathermapComponent;