import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.apply.item');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import showdown from 'showdown';
import { Location } from '@angular/common'
import ClassicEditor from "src/libs/ckeditor/ckeditor";

@Component({
    selector: 'wiz-portal-apply-item',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.apply.item/view.scss */
.wiz-view {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

label {
  margin-left: auto;
}

.filebox {
  display: flex;
  justify-content: flex-end;
}

.btn-file {
  width: 127px;
  height: 44px;
  background: #F2F2F2;
  color: #6D6D6D;
  border: none;
  font: normal normal bold 18px/21px NanumSquareOTF;
  border-radius: 10px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-file img {
  filter: invert(45%) sepia(0%) saturate(0%) hue-rotate(70deg) brightness(94%) contrast(88%);
}
.btn-file:hover {
  background: #5263FF;
  color: #fff;
}

.btn-remove {
  height: 30px !important;
  width: 70px !important;
  margin-left: auto;
  border: none;
  background-color: #F6F9FE;
  margin-right: 5px;
}

#file {
  display: none;
}

.content {
  padding: 60px 25px 25px 25px;
  width: 100%;
  height: 100%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
}
.content h2 {
  margin: 0 auto;
  text-align: center;
}
.content button {
  width: 336px;
  height: 76px;
  font: normal normal normal 24px/27px NanumSquareOTF;
  border: none;
  border-radius: 10px;
}

.wiz-form {
  margin: 0 auto;
  max-width: 1440px;
}
.wiz-form .wiz-form-rows {
  min-height: 80px;
}

.season-page {
  position: relative;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px auto;
  border-radius: none;
  vertical-align: middle;
  text-align: left;
}

.sticky-top {
  padding: 8px 48px;
  position: sticky;
}
.sticky-top .text-align-center {
  justify-content: center;
  align-items: center;
}

li {
  background: #F6F9FE 0% 0% no-repeat padding-box;
  border-radius: 10px;
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
li a {
  text-decoration: underline;
}
li img {
  filter: invert(17%) sepia(41%) saturate(7462%) hue-rotate(199deg) brightness(89%) contrast(102%);
  width: 20px;
  height: 20px;
}

.var {
  align-items: center;
  margin: auto;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(6px);
}

.season-page::-webkit-scrollbar {
  display: none;
}

.header {
  background-image: url("/assets/portal/apply/featured/banner.png");
  min-height: 300px;
  max-height: 300px;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.header .text-container {
  margin: 0 auto;
  max-width: 1440px;
  width: 100%;
  padding: 0 20px;
}

.text-container {
  width: 100%;
  margin: 0 auto;
}

.content-container {
  padding: 64px auto;
  font-size: 18px;
  width: 1440px;
  min-height: 300px;
  margin: 0 auto;
}
.content-container .content-body {
  max-width: 1180px;
  margin: 40px auto 60px auto;
  height: 100%;
  text-align: left;
}

.btn-apply {
  margin-top: 30px;
  margin-left: 30px;
  background-color: #5263ff;
  color: white;
}

.btn-list {
  margin-top: 30px;
  background-color: #D1D1D1;
}`],
})
export class PortalApplyItemComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Location)  public location: Location) { }

    @Input() post_id: any;
    public contentEditor: any;
    public item: any = {
        user_id: '',
        title: '',
        content: '',
        status: '',
        category: 'new',
        featured_image: '',
        planstart: '',
        planend: '',
        files: []
    };
    public user: any;
    public data: any = {};
    public fd = new FormData();
    public subForm: any = {
        submit_id: '',
        user_id: '',
        apply_title: '',
        title: '',
        content: '',
        email: '',
        institute: '',
        name: '',
        tel: '',
        category: '',
        files: []
    };
    public tab: string = "content";
    public user_email: string;

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }
    public async init() {
        this.subForm.user_id = WizRoute.segment.id;
    }

    public async load() {
        this.user_email = this.service.auth.session.email
        let user_info = this.service.auth.session

        const { code, data } = await wiz.call("get", { id: this.post_id, email: this.user_email });
        this.item = data.item;
        this.item.files = JSON.parse(data.item.files.replace(/'/g, '"'));
        this.user = data.user;

        const CONTENT_ID = 'textarea#content';
        const Content = this.item.content || '';
        this.contentEditor = await this.createEditor(CONTENT_ID, Content);

        await this.service.render();

    }

    public async createEditor(selector: string, content: string) {
        const opt = {
            toolbar: {
                items: 'heading | bold italic strikethrough underline | fontColor highlight fontBackgroundColor | bulletedList numberedList todoList | outdent indent | insertTable | link blockQuote code codeBlock'.split(' '),
                shouldNotGroupWhenFull: true,
            },
            removePlugins: ['MediaEmbedToolbar', 'Markdown'],
            table: ClassicEditor.defaultConfig.table,
        };

        const editorInstance = await ClassicEditor.create(document.querySelector(selector), opt);
        
        if (content) {
            editorInstance.data.set(content);
        } else {
            editorInstance.data.set('');
        }
        editorInstance.isReadOnly = true;
        editorInstance.ui.view.toolbar.element.style.display = 'none';

        const editorElement = editorInstance.ui.getEditableElement();

        editorElement.style.border = 'none';
        editorElement.style.height = 'auto';
        editorElement.style.setProperty('padding-bottom', '0', 'important');
        editorElement.style.display = 'block';
        return editorInstance;
    }

    public async alert(message: string, title: any = "", status: any = "success", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            status: status,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,

        });
    }
    public async submit() {
        if (this.item.category == 'noti') {
            if (this.subForm.title.length == 0) {
                await this.alert('제목을 입력해주세요.');
                return
            }
            if (this.subForm.content.length == 0) {
                await this.alert('내용을 입력해주세요.');
                return
            }
        }

        if (this.subForm.files.length == 0 && this.item.category == 'report') {
            await this.alert('보고서를 제출해주세요.');
            return
        }

        this.subForm.apply_title = this.item.title;
        this.subForm.category = this.item.category;
        this.subForm.submit_id = this.post_id;
        this.subForm.email = this.user.email;
        this.subForm.institute = this.user.institute;
        this.subForm.name = this.user.name;
        this.subForm.tel = this.user.tel;


        let subForm = this.subForm
        this.fd.append("data", JSON.stringify(subForm))

        let url = wiz.url('submit')

        const { code, data } = await this.service.file.upload(url, this.fd);

        if (code === 200 && this.item.category == 'noti') {
            await this.alert('접수 되었습니다.', '', 'success', '확인');
            location.href = '/apply';
            await this.emailAuthentication();
        } else if (code === 200 && this.item.category == 'report') {
            await this.alert('제출 되었습니다.', '', 'success', '확인');
            location.href = '/apply';
        }
        else await this.alert('오류가 발생했습니다.')

    }


    public async imgUpload(e) {
        for (let i = 0; i < e.target.files.length; i++) {
            let file = e.target.files[i]
            this.filename = file.name;
            if (!file.filepath) file.filepath = file.name;
            this.fd.append('file[]', file);
            this.subForm.files.push(file.filepath);
        }
        await this.service.render();
    }

    public async removeImage(id, index) {
        if (id == 'post') {
            this.post.files.splice(index, 1)
        }

        for (let i = 0; i < this.comments.length; i++) {
            let comment = this.comments[i]
            if (comment['id'] == id) {
                comment.files.splice(index, 1);
                this.post.files = comment.files
                await this.upload();
                await this.service.render();
            }
        }

    }

    public async removeFile(i) {
        this.subForm.files.splice(i, 1)
    }
    public async emailAuthentication() {
        const { code, data } = await wiz.call('emailAuthentication', { email: this.user_email });
    }

    public desc() {
        let item: any = this.item;
        let text: any = item.content;
        let converter = new showdown.Converter();
        text = converter.makeHtml(text);
        return text;
    }

    public back() {
        this.location.back();
    }

    public async tabChange(tab: string) {
        this.tab = tab;
        if (this.tab == 'content') {
            await this.load();
        }

    }


}

export default PortalApplyItemComponent;