import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.kreonet.popup.devices');
import { OnInit } from '@angular/core';
import { Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/portal/season/service";

@Component({
    selector: 'wiz-portal-kreonet-popup-devices',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.kreonet.popup.devices/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  z-index: 4000;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 860px;
}
.wiz-modal .modal-dialog {
  width: 100%;
  max-width: 860px;
}
.wiz-modal .modal-content {
  width: 100%;
  max-width: 860px;
}

.btns-location {
  text-align: center;
}
.btns-location .btn {
  padding-right: 8px;
  padding-left: 8px;
  margin-right: 4px;
  margin-bottom: 4px;
}

.selected-item-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.selected-item-container .selected-header {
  background: var(--tblr-light);
  border-bottom: 1px solid var(--wc-border);
  padding: 4px 8px;
}
.selected-item-container .list-group {
  flex: 1;
}`],
})
export class PortalKreonetPopupDevicesComponent implements OnInit {
    @Input() preset: any = [];
    @Input() label: any = [];
    @Input() mode: string = 'multiple';
    @Input() target: string = '대전';
    @Output() select = new EventEmitter<any>();
    @Output() cancel = new EventEmitter<any>();

    public location: any = [];
    public data: any = [];
    public selected: any = [];
    public keyword: string = '';

    constructor(@Inject( Service) public service: Service) {
    }

    public async ngOnInit() {
        await this.service.init();
        await this.search();
        for (let i = 0; i < this.preset.length; i++)
            this.selected.push(this.preset[i]);
        await this.service.render();
    }

    public async search(loc: string | null = null) {
        if (loc) this.target = loc;
        let res = await wiz.call("search", { location: this.target });

        let data = res.data.data;

        let devices = {};
        for (let i = 0; i < data.length; i++) {
            let device = data[i].device;
            if (!devices[device]) devices[device] = { device: device, items: [] };
            devices[device].items.push(data[i])
        }

        this.data = [];
        for (let key in devices) {
            this.data.push(devices[key]);
        }

        this.location = res.data.location;

        await this.service.render();
    }

    public match(item: any) {
        if (this.keyword.length == 0) return true;
        let keyword = this.keyword.toLowerCase()
        if (item.ifAlias.toLowerCase().indexOf(keyword) >= 0) return true;
        if (item.ifAlias.toLowerCase().indexOf(keyword) >= 0) return true;
        if (item.ifDescr.toLowerCase().indexOf(keyword) >= 0) return true;
        return false;
    }

    public find(item: any) {
        for (let i = 0; i < this.selected.length; i++) {
            if (this.selected[i].id == item.id) {
                return this.selected[i];
            }
        }
        return null;
    }

    public async addIn(item: any) {
        let preset = this.find(item);
        if (!preset) {
            preset = JSON.parse(JSON.stringify(item));
            this.selected.push(preset);
        }
        preset.in = true;
        await this.service.render();
    }

    public async addOut(item: any) {
        let preset = this.find(item);
        if (!preset) {
            preset = JSON.parse(JSON.stringify(item));
            this.selected.push(preset);
        }
        preset.out = true;
        await this.service.render();
    }

    public async removeIn(item: any) {
        let preset = this.find(item);
        preset.in = false;
        if (!preset.out && !preset.in) this.selected.remove(preset);
        await this.service.render();
    }

    public async removeOut(item: any) {
        let preset = this.find(item);
        preset.out = false;
        if (!preset.out && !preset.in) this.selected.remove(preset);
        await this.service.render();
    }


    public async hide() {
        this.cancel.emit();
    }

    public async action() {
        let selected = JSON.parse(JSON.stringify(this.selected));
        this.select.emit(selected);
    }
}

export default PortalKreonetPopupDevicesComponent;