import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.institutes.event');
import { OnInit, OnDestroy, Input } from '@angular/core';
import { ElementRef, ViewChild } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Project } from "src/libs/portal/works/project";

@Component({
    selector: 'wiz-page-institutes-event',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/page.institutes.event/view.scss */
.workspace {
  padding: 0 24px;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  z-index: 999;
}

.col-auto {
  display: flex;
  flex-direction: row;
}

.pagenation {
  margin-top: 50px;
}

.list-header {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.list-header .btn-blue {
  width: 132px;
  height: 44px;
}

.bold {
  font-weight: bold;
}

.search {
  background: white;
}
.search i {
  width: 18px;
  height: 18px;
  color: white;
}
.search .form-control {
  border-radius: 10px 0 0 10px;
  width: 349px;
  height: 44px;
  border: none;
  background: #F7F7F7 0% 0% no-repeat padding-box;
  padding-left: 25px;
  z-index: 3;
  color: var(--unnamed-color-6d6d6d);
  text-align: left;
  font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) var(--unnamed-font-size-18)/var(--unnamed-line-spacing-21) var(--unnamed-font-family-nanumsquareotf);
  box-shadow: none;
}
.search .btn-search {
  width: 44px;
  height: 44px;
  border: none;
  background: #5263FF 0% 0% no-repeat padding-box;
  border-radius: 0px 10px 10px 0px;
  z-index: 3;
}

.dropdown a {
  width: 132px;
  height: 44px;
  border: 1px solid #5263FF;
  border-radius: 10px;
  text-align: left;
  font: normal normal normal 16px/18px NanumSquareOTF;
  margin-left: 900px;
}

.content {
  margin: 0;
  padding: 0;
  height: 100%;
  position: relative;
}

.btn-blue {
  width: 132px;
  height: 44px;
  border-radius: 10px;
}

.work-container {
  display: table;
  width: max-content;
  height: 100%;
  overflow: hidden;
}
.work-container .work-group {
  display: table-cell;
  width: 320px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  border-right: 1px solid var(--wc-border);
}
.work-container .work-group .work-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding-top: 2px;
}
.work-container .work-group .work-header {
  display: flex;
  height: 46px;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid var(--wc-border);
}
.work-container .work-group .work-header input {
  font-size: 16px;
  font-family: "main-eb";
}
.work-container .work-group .work-header .btn {
  min-width: 24px;
}
.work-container .work-group .work-body {
  flex: 1;
  overflow-y: auto;
  padding: 0 16px;
}
@media (max-width: 768px) {
  .work-container .work-group .work-body {
    padding: 0 32px;
  }
}
.work-container .btn-action-move {
  cursor: grab !important;
}
.work-container .label-container {
  height: 100%;
  display: table;
  width: max-content;
  overflow: hidden;
}
.work-container .issue-container {
  min-height: 160px;
}

.empty img {
  width: 80%;
  max-width: 240px;
}

.issue-item {
  border-radius: 16px;
  margin-bottom: 12px;
}
.issue-item:first-child {
  margin-top: 12px;
}
@media (max-width: 768px) {
  .issue-item {
    margin-bottom: 16px;
  }
}

.status-items {
  font-size: 13px;
}

.issue-layout {
  position: relative;
  width: 100%;
  height: 100%;
}

.scroll-left {
  position: absolute;
  top: 49%;
  left: 0;
  z-index: 1000;
  font-size: 24px;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .scroll-left {
    display: none;
  }
}
.scroll-left:hover {
  background-color: var(--wc-border);
}

.badge {
  width: 84px;
  height: 34px;
  font: normal normal normal 18px/21px NanumSquareOTF;
  text-align: center;
}

.scroll-right {
  position: absolute;
  top: 49%;
  right: 0;
  z-index: 1000;
  font-size: 24px;
  padding: 12px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .scroll-right {
    display: none;
  }
}
.scroll-right:hover {
  background-color: var(--wc-border);
}

.card {
  max-width: 1440px;
  width: 90%;
  height: 85%;
  margin: 0 auto;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  border: none;
}

.row {
  padding: 1rem 2rem;
}
.row .form-select {
  border: 1px solid #929DAB;
  border-radius: 10px;
  width: 132px;
  height: 44px;
}

.page {
  margin-top: 40px;
  margin-bottom: 40px;
}

.table-responsive {
  padding: 0 1rem;
}

.table-last {
  max-width: 1380px;
  margin: 0 auto;
}
.table-last thead {
  border-top: solid 2px #D1D1D1;
  border-bottom: solid 2px #D1D1D1;
  text-align: left;
  font: normal normal bold 17px/19px NanumSquareOTF;
  height: 51px;
}
.table-last thead th {
  min-width: 6rem;
  font: normal normal bold 17px/19px NanumSquareOTF;
  background: none;
  vertical-align: middle;
  color: #000000;
}
.table-last tbody tr {
  width: 1380px;
  height: 70px;
  border-bottom: solid 0.5px #D1D1D1;
  vertical-align: middle;
}
.table-last tbody tr.selected-row {
  background: #FAFAFA;
}
.table-last tbody tr td {
  height: 60px;
  min-width: 6rem;
  font: normal normal normal 18px/21px NanumSquareOTF;
}`],
})
export class PageInstitutesEventComponent implements OnInit, OnDestroy {

    public institute_ns: string = '';
    constructor(@Inject( Service)         public service: Service,@Inject( Project    )         public project: Project    ) {
        this.institute_ns = WizRoute.segment.id
    }
    private status = "default";
    public post_id: string = '';
    public items: any = [];
    public loaded: boolean = false;
    public user_name: string;
    public search_form: any = { page: 1, ns: this.institute_ns };
    public loading = true;
    public search_word = '';
    public data: any = {
        info: {}
    }
    public pagenation: any = {
        page: 1,
        end: 1,
        start: 1,
    };
    public user_name = '';

    @ViewChild('container')
    public workspace: ElementRef;

    public socket: any = null;
    public labels: any = [];

    public readOnly: boolean = true;

    public cache: any = {
        label: '',
        loaded: false,
        issues: {},
        hiddenIssues: {}
    };

    public issue: any = {
        id: null,
        modal: false,
        event: {}
    };
    private search_word = "";


    public config: any = {
        labelSorted: {
            animation: 0,
            handle: '.btn-action-move'
        },
        issueSorted: {
            animation: 0,
            handle: '.card',
            group: 'issue'
        }
    };


    public async alert(message: string, title: any = "", status: any = "error", action: string = '확인', cancel: any = false) {
        return await this.service.alert.show({
            title: title,
            message: message,
            cancel: cancel,
            actionBtn: status,
            action: action,
            status: status
        });
    }
    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow(true, '/');
        await this.load();
    }

    public async ngDoCheck() {
        if (WizRoute.segment.id != this.institute_ns) {
            this.institute_ns = WizRoute.segment.id;
            this.search.ns = this.institute_ns;
            await this.load();
        }
    }

    public async loadChange(loading: boolean) {
        this.loading = loading;
        this.pageLoad(1);
    }

    private async search() {
        if (this.search_word.length === 0) {
            await this.alert("검색어를 입력해주세요.");
            await this.load();
        }

        this.pagenation.page = 1;
        await this.load();
    }

    private async move(index: number) {
        this.pagenation.page = index;
        await this.load();
    }

    public async load() {
        this.loaded = false;
        this.search.ns = this.institute_ns
        this.user_name = this.service.auth.session.name
        const { code, data } = await wiz.call("load", { ns: this.institute_ns, page: this.pagenation.page, status: this.status, text: this.search_word });
        if (code != 200) return;
        let { rows, lastpage, info } = data;
        this.items = rows;
        this.data.info = info
        this.pagenation.start = Math.floor((this.pagenation.page - 1) / 10) * 10 + 1;
        this.pagenation.end = lastpage;
        this.service.institute = this.data;
        this.loaded = true;

        await this.service.render();
    }

    private changeStatus() {
        this.search_word = '';
        this.pageLoad(1);
    }

    private pageLoad(p: number) {
        this.pagenation.page = p;
        this.load();
    }

    private detail(id: string) {
        this.post_id = id;
        this.loading = false;
    }
}

export default PageInstitutesEventComponent;