import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('portal.works.widget.project.issueboard.issue');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/portal/season/service";
import { Project } from "src/libs/portal/works/project";

@Component({
    selector: 'wiz-portal-works-widget-project-issueboard-issue',
template: templateSource || '',
    styles: [`

/* file: /var/www/kreonet/project/main/build/src/app/portal.works.widget.project.issueboard.issue/view.scss */
.issue-card {
  cursor: pointer;
}
.issue-card:hover {
  border: 2px solid var(--wc-blue);
}

.card-title {
  word-break: break-all;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
}

.progress-bar {
  border-radius: 0;
}

.title-area {
  padding: 16px;
  padding-top: 12px;
  padding-bottom: 4px;
}`],
})
export class PortalWorksWidgetProjectIssueboardIssueComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( Project    )         public project: Project    ) { }

    @Input() issue: any;

    public async ngOnInit() {
        await this.service.init();
    }

    public color() {
        if (!this.issue) return '';
        if (this.issue.status == 'close' || this.issue.status == 'cancel') return '';
        if (this.issue.status == 'finish' && this.issue.process == 100) return 'bg-teal-lt';
        if (this.issue.planend) {
            try {
                let endtime = new Date(this.issue.planend).getTime();
                let now = new Date().getTime();
                if (now - endtime > 1000 * 60 * 60 * 24)
                    return 'bg-red-lt';
            } catch (e) {
            }
        }
        return '';
    }
}

export default PortalWorksWidgetProjectIssueboardIssueComponent;